import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import MaisInfos from "./components/MaisInfos";
import WiseAPI from "wise-api";
import "./assets/css/App.css";
import logo2x from "./assets/imagens/logo@2x.png";
import sem_camera from "./assets/imagens/sem-camera@2x.png";
import sem_microfone from "./assets/imagens/sem-microfone.png";
import com_microfone from "./assets/imagens/com-microfone.png";
import com_camera from "./assets/imagens/com-camera.png";


function App() {
  const carregandoRef = useRef(true);
  const medicoRef = useRef(null);

  const [idSessao, setIdSessao] = useState("");
  const [nomePacienteD, setNomePacienteD] = useState("");
  const [mostrarMensagemFinal, setMostrarMensagemFinal] = useState(false);
  const [exibirTelemedicina, setExibirTelemedicina] = useState(true);
  const [exibirPreenchimento, setExibirPreenchimento] = useState(true);
  const [exibirMedico, setExibirMedico] = useState(true);
  const [podeConfig, setPodeConfig] = useState(false);
  const [nomeInvalido, setNomeInvalido] = useState(false);
  const [sessaoValida, setSessaoValida] = useState(false);
  const [profissional, setProfissional] = useState({ nome: '' });
  const [dispositivos, setDispositivos] = useState([
    {
      tipo: 'MICROFONE',
      ligado: false,
      texto: 'OFF',
      srcOn: require('./assets/imagens/com-microfone.png'),
      srcOff: require('./assets/imagens/sem-microfone.png'),
    },
    {
      tipo: 'CÂMERA',
      ligado: false,
      texto: 'OFF',
      scrOn: require('./assets/imagens/com-camera.png'),
      scrOff: require('./assets/imagens/sem-camera.png'),
    },
  ]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const idSess = urlParams.get('sessao');

    if (!idSess) {
      carregandoRef.current = true;
      return;
    }

    setIdSessao(idSess);
  }, []);

  useEffect(() => {

    if (!idSessao) return;

    const obterDados = async () => {
      const resposta = await obterProfissional(idSessao);
      const fraseBoasVindas = document.getElementsByClassName("frase-boas-vindas")[0];

      if (!resposta.ok) {
        fraseBoasVindas.firstChild.textContent =
          "Esta sala não existe. Por favor, verifique sua URL.";
      }else{
        setProfissional({ nome: resposta.name });

        configMutationObserver();

        const botaoEntrar = document.querySelector("button");
        botaoEntrar.style.cssText = "opacity: 1; cursor: pointer;";
        setPodeConfig(true);
      }

      carregandoRef.current= false;
      fraseBoasVindas.style.opacity = "1";

    };
    obterDados();
  }, [idSessao]);

  
  const configMutationObserver = () => {
    const observador = new MutationObserver((mutations) => {
      if (mutations) {
        const iFrameV4H = [...mutations[0].addedNodes].find(
          (node) =>
            node.id === 'jitsiConferenceFrame0' || node.tagName === 'IFRAME'
        );

        if (iFrameV4H) {
          iFrameV4H.style.width = "100%";
          iFrameV4H.style.height = "100%";
          iFrameV4H.style.boxSizing  = "content-box";}
      }
    });

    const opcoesObservador = { childList: true, subtree: true };
    observador.observe(document.body, opcoesObservador);
  };

  const alertaNomeValido = () => {
    const inputPaciente = document.getElementsByClassName("paciente")[0];
    const nomePaciente = inputPaciente.lastChild.value;
  
    if (nomePaciente.length > 1) return true;
  
    if (podeConfig) {
      inputPaciente.lastChild.style.border = "1px solid #EE8484";
      setNomeInvalido(true);
      setTimeout(() => {
        inputPaciente.lastChild.style.border = "";
        setNomeInvalido(false);
      }, 3000);
      return false;
    }
  };

  const nomePac = (event) => {
    setNomePacienteD(event.target.value);
  };

  const ilustrarProfissional = (profissional) => {
    if (medicoRef.current) {
      medicoRef.current.textContent = `Consulta com ${profissional.nome}`;
      setTimeout(() => (medicoRef.current.style.opacity = "1"), 500);
    }
  };

  const obterProfissional = async (idSessao) => {
    const reqUrl = process.env.REACT_APP_NORDEN_URL + idSessao;
    const reqOpcoes = { method: "GET" };

    const req = await fetch(reqUrl, reqOpcoes);

    if (req.ok) {
      const _json = await req.json();
      _json.ok = req.ok;
      return _json;
    }
    return req;
  };

  const mudarEstadoDispositivo = (tipo) => {
    const index = tipo === "MICROFONE" ? 0 : 1;
    const novosDispositivos = [...dispositivos];
    novosDispositivos[index].ligado = !novosDispositivos[index].ligado;
    novosDispositivos[index].texto = novosDispositivos[index].ligado
      ? "ON"
      : "OFF";
    setDispositivos(novosDispositivos);

    if (tipo === "CÂMERA") {
      mudarEstadoCamera();
    } else {
      mudarEstadoMicrofone();
    }
  };

  const mudarEstadoCamera = async () => {
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      alert("Não é possível usar a câmera.");
      return;
    }

    const video = document.querySelector("video");
    const cameraLigada = dispositivos[1].ligado;
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });

    if (cameraLigada) {
      video.srcObject = mediaStream;
      video.play();
    } else {
      video.srcObject = null;
      video.pause();
      mediaStream.getVideoTracks()[0].enabled = false;
      mediaStream.getVideoTracks()[0].stop();
    }
  };

  const mudarEstadoMicrofone = async () => {
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      alert("Não é possível usar o microfone.");
      return;
    }

    const microfone = dispositivos[0];
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    window.localStream = microfone.ligado ? stream : null;
    //window.localAudio.srcObject = microfone.ligado ? stream : null;
  };

  const mensagemFinal = () => {
    setMostrarMensagemFinal(true);
    setExibirTelemedicina(false);
    setExibirPreenchimento(false);
    setExibirMedico(false);
  };
  
  const entrarConsulta = async () => { {
    if (carregandoRef.current) return;

    const nomePaciente = alertaNomeValido();
    
    if (!nomePaciente || !profissional.nome || !idSessao) return;
  
    setSessaoValida(true); 

    const larguraTela = window.innerHeight;
    const divTelemedicina = document.getElementById('telemedicina')
    if (larguraTela <= 768) {
      divTelemedicina.style.height = "550px";
    } else if (larguraTela <= 1024) {
      divTelemedicina.style.height = "750px";
    } else {
      divTelemedicina.style.height = "850px";
    }

    const v4h = await WiseAPI({
      baseUrl: process.env.REACT_APP_V4H_URL,
      login: process.env.REACT_APP_V4H_USER,
      senha: process.env.REACT_APP_V4H_PW,
    }).catch((err) => {
      console.log('Error: ' + err);
    });

    const room = await v4h.room.create({
      org: "nordenhospital",
      orgUnit: "orgnordenhospital",
      profile: "DEFAULT",
      skin: "DEFAULT",
      service: "ATN4-50",
      joinPolicy: "PUBLIC",
      listPolicy: "PUBLIC",
      openPolicy: "PUBLIC"
    }).catch((err) => {
      console.log('Error Room: ' + err);
    });

    const options = {
      parentNode: divTelemedicina,
      startWithAudioMuted: !dispositivos[0].ligado,
      startWithVideoMuted: !dispositivos[1].ligado,
      userInfo: { displayName: nomePacienteD },
      shareLink: process.env.REACT_APP_SHARE_URL + idSessao,
      buttons: ['microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'hangup', 'profile', 'info', 'chat', 'recording',
        'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
        'videoquality', 'filmstrip', 'feedback', 'participants-pane'],
      onClose: mensagemFinal,
    };

    await v4h.room.open(room.name);
  
    const infoToJoin = await v4h.room.join(room.name);

    await v4h.session.startConference(infoToJoin.sessionId, options);
    
    ilustrarProfissional(profissional);
  
    const header = document.getElementsByClassName("header")[0];
    const body = document.body.clientHeight;
    const alturaHeader = header.clientHeight;
    const alturaTelemed = divTelemedicina.clientHeight;
    const alturaPreenchimento = body - (alturaTelemed + alturaHeader);
    const divPreenchimento = document.getElementById("preenchimento");
    divPreenchimento.style.height = alturaPreenchimento + "px";
    divPreenchimento.style.backgroundColor = "rgb(242, 233, 213)";}
};
return (
  <div id="app">
    <Helmet>
        <title>Consulta Telemedicina</title>
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    </Helmet>
    <div className="header">
      <a href="https://nordenhospital.com" target="_blank" rel="noopener noreferrer">
        <img src={logo2x} alt="Logo" />
      </a>
      {sessaoValida && exibirMedico && <p ref={medicoRef} className="medico"></p>}
    </div>
    {exibirTelemedicina && <div id="telemedicina"></div>}
    
    <CSSTransition in={(!sessaoValida)} timeout={300} classNames="fade" unmountOnExit>
      <div className="divisor">
        <div></div>
        <div></div>
      </div>
    </CSSTransition>

    {/* Frase de boas vindas. */}
    <CSSTransition in={!sessaoValida} timeout={300} classNames="fade" unmountOnExit>
      <div className="container">
        <div className="frase-boas-vindas">
          <p>
            Olá, bem-vindo(a) à sua consulta com o(a) {profissional.nome}.
          </p>
        </div>
        
        <div className="orientacao">
          {/* Câmera */}
          <div className="camera">
            <video></video>
            {!dispositivos[1].ligado && (
              <img
                className={carregandoRef.current ? 'carregando' : ''}
                src={sem_camera}
                alt="Sem câmera"
              />
            )}
          </div>

          <div className="tela-config">
            {/* Configuração de câmera e microfone */}
            <div className="configuracao">
              <div>
                <label>{dispositivos[0].tipo}</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      mudarEstadoDispositivo(dispositivos[0].tipo)
                    }
                  />
                  <span className="slider round">
                    {!dispositivos[0].ligado ? (
                      <img src={sem_microfone} alt="Sem microfone" />
                    ) : (
                      <img
                        className={dispositivos[0].ligado ? 'ativo' : ''}
                        src={com_microfone}
                        alt="Com microfone"
                      />
                    )}
                  </span>
                </label>
              </div>
              <div>
                <label>{dispositivos[1].tipo}</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      mudarEstadoDispositivo(dispositivos[1].tipo)
                    }
                  />
                  <span className="slider round">
                    {!dispositivos[1].ligado ? (
                      <img src={sem_camera} alt="Sem câmera" />
                    ) : (
                      <img
                        className={dispositivos[1].ligado ? 'ativo' : ''}
                        src={com_camera}
                        alt="Com câmera"
                      />
                    )}
                    </span>
                  </label>
                </div>
              </div>

              {/* Nome e sobrenome do(a) paciente */}
              <div className="paciente">
                <label>NOME E SOBRENOME</label>
                <br />
                <input type="text" placeholder="Insira seu nome e sobrenome" 
                  value={nomePacienteD} onChange={nomePac}/>
              </div>

              {/* Botão */}
              <div>
                <button className="botao-entrar" onClick={entrarConsulta}>
                  ENTRAR NA CONSULTA
                </button>
              </div>
          </div>
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={(nomeInvalido) && (podeConfig)} timeout={300} classNames="fade" unmountOnExit>
      <div className="caixa-aviso">
        <p>Nome inválido. Por favor, insira seu nome antes de entrar na consulta.</p>
      </div>
    </CSSTransition>
    
    <CSSTransition in={(mostrarMensagemFinal) && (sessaoValida)} timeout={300} classNames="fade" unmountOnExit>
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70%",
          }}
        >
          <p style={{ fontSize: "3vh" }}>
            Basta fechar esta aba para sair do ambiente de telemedicina. Até logo!
          </p>
      </div>
    </CSSTransition>

    <CSSTransition in={(!sessaoValida)} timeout={300} classNames="fade" unmountOnExit>
      <MaisInfos />
    </CSSTransition>
    
    {exibirPreenchimento && <div id="preenchimento"></div>}
  </div>
  );
}
export default App;