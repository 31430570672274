import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import "../assets/css/ComponenteInterrogacao.css";

const ComponenteInterrogacao = () => {
  const [mostrarInfos, setMostrarInfos] = useState(false);

  const toggleMostrarInfos = () => {
    setMostrarInfos(!mostrarInfos);
  };

  return (
    <div className="interrogacao">
      <CSSTransition in={mostrarInfos} timeout={100} classNames="fade" unmountOnExit>      
        <div className='caixa-info'>
          <a href="https://meunorden.com/politica-de-privacidade" target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
          <a href="https://meunorden.com/termos-de-uso" target="_blank" rel="noopener noreferrer">Termos de Uso</a>
        </div>
      </CSSTransition>
      <p onClick={toggleMostrarInfos}>?</p>
    </div>
  );
};

export default ComponenteInterrogacao;
